<template>
  <div class="pa-16"
       style="height: 100%; width: 100%; display: flex; align-items: center; justify-content: center;">
    <v-card flat>
      <v-card-text class="success--text font-weight-medium" style="font-size: 10rem; text-align: center; line-height: 10rem">
        Сторінка заблокована...
      </v-card-text>
      <v-card-text class="grey--text text--darken-1" style="font-size: 4rem; text-align: center; line-height: 4rem">
        На даний момент ця сторінка заблокована. Зверніться будь ласка до адміністратора. Дякуємо
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "SiteBlocked"
}
</script>

<style scoped>

</style>